<template>
    <div></div>
</template>

<script>
import { ssoConfig } from '@/assets/javascripts/setting.js'
import {
    getTokenAPI,
    getUserInfoAPI,
    getAccountCodesAPI
} from '@/assets/javascripts/api.js'

export default {
    name: 'Sso',
    methods: {
        getSsoToken: async function () {
            try {
                const ssoResponse = this.$getUrlParam()
                if (ssoResponse.code) {
                    const payload = `grant_type=authorization_code&code=${ssoResponse.code}&redirect_uri=${ssoConfig.sso_uri}&client_id=bms`
                    const res = await getTokenAPI(payload)
                    this.$store.state.getTokenTime = Date.now()
                    return res.data
                }
            } catch {
                this.$hideLoading()
                this.$showErrorMessage('登入失敗，請再次重新登入', '', () => {
                    this.$router.push('/')
                })
            }
        },
        getUserInfo: async function () {
            try {
                const res = await getUserInfoAPI()
                return res.data
            } catch {
                this.$hideLoading()
                this.$showErrorMessage(
                    '系統發生問題，請稍後重新登入再試',
                    '',
                    () => {
                        this.$logout()
                    }
                )
            }
        },
        redirectLastPage: async function () {
            const lastPagePath = this.$store.state.lastPagePath
            if (lastPagePath) {
                this.$store.commit('set', { lastPagePath: '' })
                this.$router.push({ path: lastPagePath })
            } else {
                this.$router.push('/home')
            }
        },
        storeToken: function (sso) {
            const tokenType = sso.token_type
            const id_token = sso.id_token
            const accessToken = sso.access_token
            const refreshToken = sso.refresh_token
            const authorization = `${tokenType} ${accessToken}`
            const tokenExpireTime = sso.expires_in
            this.$store.commit('set', {
                id_token: id_token,
                token: authorization,
                refreshToken: refreshToken,
                tokenExpireTime
            })
        },
        storeUserInfo: function (userInfo) {
            this.$store.commit('set', {
                userInfo: userInfo
            })
        },
        getAccountCodes: async function () {
            try {
                const res = await getAccountCodesAPI()
                let accounts = res.data?.accounts
                let titleTypes = res.data?.titleTypes
                let invalid = !accounts || !titleTypes
                if (invalid) throw '帳號錯誤'
                return res.data
            } catch {
                this.$hideLoading()
                this.$showErrorMessage('帳號有誤，請聯繫資訊客服', '', () => {
                    this.$router.push('/')
                })
            }
        },
        setAccountCodes(accountCodes) {
            const ac = this.$transformInputType(accountCodes)
            const accounts = ac.accounts
            const titleTypes = ac.titleTypes

            this.$store.state.accounts = accounts
            this.$store.state.titleTypes = titleTypes
                .filter((type) => type.value)
                .sort((a, b) => {
                    return a.value.localeCompare(b.value)
                })
        },
        storePermissionList: function (permission = []) {
            let permissionList = {}
            permission.forEach((item) => {
                //轉成前端對應的頁面名稱
                const allPageValue = item.slice(0, item.indexOf('/'))
                const removePagePrefix = allPageValue
                    .slice(allPageValue.indexOf('bms.') + 4)
                    .split('.')
                const pageName = removePagePrefix
                    .map((str) => {
                        return (str = this.$firstCapitalized(str))
                    })
                    .join('')

                const action = item.slice(item.indexOf('/') + 1) //畫面上執行API的項目
                if (permissionList[pageName]) {
                    permissionList[pageName].push(action)
                } else {
                    permissionList[pageName] = []
                    permissionList[pageName].push(action)
                }
            })

            this.$store.commit('set', {
                permissions: permissionList
            })
        },
        ssoPageLoadHanlder: async function () {
            if (this.$isLogin()) {
                this.$showErrorMessage(
                    '您已經成功登入！將帶您返回頁面',
                    '',
                    () => {
                        this.redirectLastPage()
                    }
                )
                return
            }
            this.$showLoading()
            const sso = await this.getSsoToken()
            this.storeToken(sso)
            const userInfo = await this.getUserInfo()
            this.storeUserInfo(userInfo)
            if (userInfo.permissions) {
                this.storePermissionList(userInfo.permissions)
            }
            const accountCodes = await this.getAccountCodes()
            this.setAccountCodes(accountCodes)
            this.$hideLoading()
            this.redirectLastPage()
        }
    },
    mounted() {
        this.ssoPageLoadHanlder()
    }
}
</script>

<style lang="scss" scoped></style>
